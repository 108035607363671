var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"decisionMake",attrs:{"id":"mainContent"}},[_c('div',{staticClass:"box"},[_c('text-title',{staticClass:"modelBox",attrs:{"index":"01","label":"会议基本信息","data-title":"会议基本信息"}}),_c('base-form',{ref:"financingConfig",staticClass:"formData",attrs:{"componentList":_vm.financingConfig,"formAttrs":{
        model: _vm.formData,
        labelWidth: '100px',
      },"showBtns":false}})],1),_c('div',{staticClass:"box"},[_c('text-title',{staticClass:"modelBox",attrs:{"index":"02","label":"批复内容","data-title":"批复内容"}}),_c('base-form',{ref:"programmeConfig",staticClass:"formData",attrs:{"componentList":_vm.programmeConfig,"formAttrs":{
        model: _vm.formData,
        labelWidth: '100px',
      },"showBtns":false}})],1),_c('div',{staticClass:"box"},[_c('text-title',{staticClass:"modelBox",attrs:{"index":"03","label":"文件生成与下载","data-title":"文件生成与下载"}}),_c('base-form',{ref:"fileConfig",staticClass:"formData",attrs:{"componentList":_vm.fileConfig,"formAttrs":{
        model: _vm.formData.fileListInfo,
        labelWidth: '100px',
      },"showBtns":false}})],1),_c('div',{staticClass:"bottom"},[(_vm.isExamineData)?_c('base-button',{attrs:{"label":"确定","type":"primary"},on:{"click":function($event){return _vm.send('determine')}}}):_vm._e(),(!_vm.isExamineData)?_c('base-button',{attrs:{"label":"保存","type":"primary"},on:{"click":function($event){return _vm.send('save')}}}):_vm._e(),(!_vm.isExamineData)?_c('base-button',{attrs:{"label":"提交","type":"primary"},on:{"click":function($event){return _vm.send('submit')}}}):_vm._e(),_c('base-button',{attrs:{"label":"关闭","type":"default"},on:{"click":_vm.close}})],1),_c('scroll-fool',{ref:"scrollFool"}),_c('pre-view',{attrs:{"fileId":_vm.fileId,"isOpen":true,"fileType":_vm.fileType,"count":_vm.fileIdCount}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }